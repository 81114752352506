import React, { useState } from "react";  
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import '../style/contact.css'

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const { register, handleSubmit, errors } = useForm();

  const serviceID = "service_ID";
  const templateID = "template_ID";
  const userID = "user_m7yz9DxmhkHoEWfowVyF1";

  const onSubmit = (data, r) => {
    sendEmail( 
      serviceID,
      templateID,
      {
        name: data.name,
        phone: data.phone,
        email: data.email,
        subject: data.subject,
        description: data.description
      },
      userID
    )
    r.target.reset();
  }

  const sendEmail = (serviceID, templateID, variables, userID) => {
    emailjs.send(serviceID, templateID, variables, userID)
      .then(() => {
        setSuccessMessage("Form sent successfully! I'll contact you as soon as possible! Thank you!");
      }).catch(err => console.error(`Something went wrong ${err}`));
  }

  return (
    <>
    <div id='contact' className="contact">
      <div className="text-center">
        <h2 className='text-uppercase text-center mt-5 pb-3'>contact me</h2>
        <p>Please fill out the form and describe your project needs.<br/> I'll contact you as soon as possible! Thank you!</p>
        <span className="success__message">{successMessage}</span>
      </div>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              {/* NAME INPUT */}
              <div className="text-center">
					<input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  ref={
                    register({
                      required: "Please enter your name, thank you.",
                      maxLength: {
                        value: 35,
                        message: "Please enter a name with fewer than 35 characters"
                      }
                    })
                  }
					/>
              </div>
              <span className="error__message">
                {errors.name && errors.name.message}
              </span>
              {/* PHONE INPUT */}
              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                  ref={
                    register({
                      required: "Please add your phone number, thank you.",
                    })
                  }
                />
                
              </div>
              <span className="error__message">
                {errors.phone && errors.phone.message}
              </span>
              {/* EMAIL INPUT */}
              <div className="text-center">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  ref={
                    register({
                      required: "Please provide your email, thank you.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid Email"
                      }
                    })
                  }
                />
                
              </div>
              <span className="error__message">
                {errors.email && errors.email.message}
              </span>
              {/* SUBJECT INPUT */}
              <div className="text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  name="subject"
                  ref={
                    register({
                      required: "OOPS, you forget to add the subject.",
                    })
                  }
                />
                
              </div>
              <span className="error__message">
                {errors.subject && errors.subject.message}
              </span>
            </div>
            <div className="col-md-6 col-xs-12">
              {/* DESCRIPTION */}
              <div className="text-center">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Please describe your project..."
                  name="description"
                  ref={
                    register({
                      required: "Please describe your project needs...",
                    })
                  }
                ></textarea>
            
              </div>
              <span className="error__message">
                {errors.description && errors.description.message}
              </span>
              <button className="contact__btn" type="submit">Contact Me</button>
            </div>
          </div>
        </form>
      </div>
      
    </div>
    </>
  )
  
}


export default Contact;
